<template>
  <div>
      <div class="loginbg">
        <div class="logo"><img src="../../assets/img/loginlogo.png" alt=""></div>
        <div class="login">
          <h1>用户登录</h1>
          <div style="position: relative;">
            <input type="text" class="inputBox" v-model="user" placeholder="请输入用户名">
            <img src="../../assets/img/usericon.png" class="usericon">
          </div>
          <div style="position: relative;">
            <input type="password" class="inputBox" v-model="pwd" placeholder="请输入密码">
            <img src="../../assets/img/pwdicon.png" class="usericon">
          </div>
           <div class="flex prompt">
               <div class="check">
                  <input  v-model="checked" type="checkbox" id="male">
                  <label for="male">记住密码</label>
                </div>
              <div>
                <a href="javascript:;" class="color999">忘记密码?</a>
              </div>
          </div>
          <div class="btn flex">
            <button class="loginbtn" @click="logins()">登 录</button>
            <button class="resedbtn">注 册</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { postLogin } from '@/api/login'
export default {
  data () {
    return {
      user: '',
      pwd: '',
      checked: false
    }
  },
  methods: {
    logins () {
      const user = this.user
      const pwd = this.pwd
      if (user === '' | user === null) {
        alert('请输入正确的用户名')
        return
      } else if (pwd === '' | pwd === null) {
        alert('请输入正确的密码')
        return
      }
      if (this.checked === true) {
        this.setCookie(user, pwd, 1)
      }
      this.setCookie(user, pwd, 1)
      const header = {
        Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        'Tenant-Id': '000000'
      }
      postLogin({
        tenantId: '000000',
        username: this.user,
        password: this.pwd,
        grant_type: 'password',
        scope: 'all',
        type: 'account'
      }, header).then((res) => {
        // const exdate = new Date() // 获取时间
        // exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 1)
        // window.document.cookie = 'access_token' + '=' + res.access_token + ';path=/;expires=' + exdate.toGMTString()
        // window.document.cookie = 'tenant_id' + '=' + res.tenant_id + ';path=/;expires=' + exdate.toGMTString()
        // window.document.cookie = 'role_name' + '=' + res.role_name + ';path=/;expires=' + exdate.toGMTString()
        // window.document.cookie = 'user_id' + '=' + res.user_id + ';path=/;expires=' + exdate.toGMTString()
        // window.document.cookie = 'role_id' + '=' + res.role_id + ';path=/;expires=' + exdate.toGMTString()
        // window.document.cookie = 'dept_id' + '=' + res.dept_id + ';path=/;expires=' + exdate.toGMTString()
        // window.document.cookie = 'account' + '=' + res.account + ';path=/;expires=' + exdate.toGMTString()
        this.$router.push('/home')
      })
    },
    // 设置cookie
    setCookie (cname, cpwd, exdays) {
      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
      // 字符串拼接cookie
      window.document.cookie = 'username' + '=' + cname + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'passwords' + '=' + cpwd + ';path=/;expires=' + exdate.toGMTString()
    },
    // 读取cooke
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ')// 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=')// 再次切割
          // 判断查找相对应的值
          if (arr2[0] === 'username') {
            this.user = arr2[1]// 保存到保存数据的地方
          } else if (arr2[0] === 'passwords') {
            this.pwd = arr2[1]
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie('', '', -1)// 修改2值都为空，天数为负1天就好了
    }
  },
  mounted () {
    this.getCookie()
  }
}
</script>

<style scoped>
  html,body{width: 100%;height: 100%;}
  .loginbg{
    background: url(../../assets/img/loginbg.png) no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
  }
  .login{
    width: 21%;
    min-height: 42%;
    background-color: #fff;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%,-50%);
    border-radius: 20px;
    padding: 50px 120px;
  }
  .logo{
    position: absolute;
    top: 0;
    right: 0%;
    width: 45%;
  }
  .login h1{
    text-align: center;
    color: #4aaedc;
    font-weight: bold;
    font-size: 30px;
  }
  .inputBox{
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 25px;
    height: 45px;
    padding-left: 40px;
    font-size: 18px;
    color: #a9a9a9;
    line-height: 45px;
  }
  .usericon{
    width: 28px;
    height: auto;
    position: absolute;
    top: 30px;
    left: 0;
  }
  .btn{
    justify-content: space-between;
    margin-top: 6%;
  }
  .btn button{
    width:46%;
    border: 0;
    height: 44px;
    font-size: 20px;
    border-radius: 5px;
    font-weight: normal;
  }
  .loginbtn{
    background-color: #50b1ea;
    color: #fff;
    text-shadow:1px 0px #fff
  }
  .resedbtn{
    color: #fff;
    background-color:#62ded1;
    text-shadow:1px 0px #fff

  }
  .prompt{
    justify-content: space-between;
    margin-top: 25px;
    font-size: 16px;
  }
  .check input{
    margin-right: 10px;
  }
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 14px;
    margin-right: 10px;
    position: relative;
}

input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #7D7D7D;
    border-radius:4px;
    color: #7D7D7D;
    font-size: 20px;
    font-weight: bold;
}
  .color999{
    color: #999;
  }
</style>
